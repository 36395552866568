<template>
	<div class="page-container">
		<p class="main-title">{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>
		<div class="page-desc">
			该报表统计所有已离职未交接的资产、员工信息以及员工未审批的单据
		</div>
		<el-form ref="form" :inline="true"  :model="search_key" prop="">
			<div  style="position: absolute;right:20px;z-index: 999;">
				<el-button  type="primary" @click="handleExport">导出全部</el-button> 
			</div>
		    <el-form-item label="">
		        <el-input v-model="search_key.keyword" placeholder="请输入关键字搜索" style="width:200px;"></el-input>
		    </el-form-item>
		    <el-button type="primary" @click="getData(1)">查询</el-button>
		</el-form>
		<el-table 
		v-loading="loading" 
		element-loading-text="查询中"
		:data="tableData" 
		 border>
			<el-table-column prop="corp_name" label="姓名">
			</el-table-column>
			<el-table-column prop="dept_name" label="部门">
			</el-table-column>
			<el-table-column prop="status" label="在职状态">
				<template slot-scope="scope">
					<span v-if="scope.row.staus < 1">离职</span>
					<span v-else>在职</span>
				</template>
			</el-table-column>
			<el-table-column prop="role_name" label="角色">
			</el-table-column>
			<el-table-column prop="asset_count" label="领用的资产" sortable> 
				<template slot-scope="scope">
					<span style="color: #409EFF;" @click="showAssetList(scope.row.id,scope.row.asset_count)">{{scope.row.asset_count}}</span>
				</template>
			</el-table-column> 
			<el-table-column prop="appro_count" label="待处理的审批单" sortable>
				<template slot-scope="scope">
					<span style="color: #409EFF;" @click="showApproval(scope.row.id,scope.row.asset_count)">{{scope.row.appro_count}}</span>
				</template>
			</el-table-column>
		</el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		<AssetListVue ref="assetRef" ></AssetListVue>
		
		<el-dialog title="" :visible.sync='showApprovalStatus' width="51%"  :close-on-click-modal="false">
			<ApprovalDialogVue ref="approval" :userId="userId" ></ApprovalDialogVue>
		</el-dialog>
	</div>
</template>
<script>
	import AssetListVue from '../Common/AssetDialog.vue'
	import ApprovalDialogVue from '../StaffAsset/Approval.vue'
	export default {
		inject: ['reload'],
		components:{
		    AssetListVue,
			ApprovalDialogVue
		},
		data() {
			return {
				loading:true,
				tableData: [],
				currentPage:1,
				pagesize:10,
				total:0,
				search_key:{
					keyword:''
				},
				dialogExportVisible:false,
				showApprovalStatus:false,
				userId:0
			}
		},
		created() {
			this.getData()
		},
		mounted() {

		},
		methods: {
			getData(search_type=0) {
				if(search_type == 1){
					this.currentPage  = 1
				}
				this.$api.post('AssetReport/dimissionStaff', {
					page:this.currentPage-1,
					limit:this.pagesize,
					keyword:this.search_key.keyword
				}, res => {
					if (res.code == 200) {
						this.tableData = res.data.list
						this.total =  res.data.count		
					} else { 
						this.$message.error(res.msg)
					}
					this.loading =  false
				})
			},
			getCurrPage(val){   //获取当前页
			    this.currentPage =  val
				this.loading =  true
			    this.getData()
			},
			getPageSize(val){   //获取每页条数
			    this.pagesize =  val
			     this.getData()
			},	
			handleExport(){
				location.href = '/api/admin/AssetReport/exportDimissionStaff'
			},
			
			//显示资产数据
			showAssetList(id,count){
				if(count <1){
					 this.$message.error('未找到资产')
					 return false
				}
				var params  = {user_id:id,id:id,count:id}
				this.$refs.assetRef.showAssetList(params)
			},
			
			//显示审批数据
			showApproval(id,count){
				if(count <1){
					 this.$message.error('未找到单据')
					 return false
				}
				this.userId = id
				this.showApprovalStatus =  true
				// this.$nextTick(()=>{
				// 	console.log('uid:',id)
				// 	this.$refs.approval.userId =  id
				// })
				
				
			}
			
		}
	}
</script>
<style>
	.table-cell .cell{
		white-space: nowrap !important; 
	}
</style>
